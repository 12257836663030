<template>
  <section>
    <v-card class="product-options-list-table__root">
      <div id="scroll-target" class="scroll-y">
        <v-card-title class="mobile-search">
          <v-text-field v-model="search" @input="debouncedSearch" append-icon="mdi-magnify" :label="$t('Search')"
                        :placeholder="$t('Start typing product name...')"
                        single-line hide-details
          />
          <div class="mobile-icon">
            <v-icon class="mobile-icon" @click="mobileButtons = !mobileButtons">mdi-reorder-horizontal</v-icon>
            <div class="mobile-buttons" v-if="mobileButtons">
              <v-btn color="primary" dark class="ml-2" @click="exportFile">{{ $t('Export') }}</v-btn>
              <v-btn color="primary" dark class="ml-2" @click="$refs.file.click()">{{ $t('Import') }}<input type="file" ref="file" accept=".csv" v-show="false" @change="uploadFile"></v-btn>
              <v-btn color="primary" dark class="ml-2" @click="openAddNewDialog">{{ $t('New Item') }}</v-btn>
            </div>
          </div>
          <div class="desktop-buttons">
            <v-btn color="primary" dark class="ml-2" @click="exportFile">{{ $t('Export') }}</v-btn>
            <v-btn color="primary" dark class="ml-2" @click="$refs.file.click()">{{ $t('Import') }}<input type="file" ref="file" accept=".csv" v-show="false" @change="uploadFile"></v-btn>
            <v-btn color="primary" dark class="ml-2" @click="openAddNewDialog">{{ $t('Add Option') }}</v-btn>
          </div>
        </v-card-title>
        <v-data-table :headers="headers" :items="productOptions" :search="search" :loading="loading"
                      :options.sync="pagination.options" :server-items-length="pagination.total"
                      @update:options="updateOptions"
                      item-key="entity_id" width="100" class="elevation-1 orders-table"
                      :footer-props="{'items-per-page-text':$t('Rows per page:')}"
        >
          <template v-slot:body="{ items }">
            <tbody class="orders-table__body_desktop">
              <product-options-list-table-line v-for="item in items" :item="item" :key="item.sku" />
            </tbody>
            <tbody class="orders-table__body_mobile">
              <product-options-list-table-line-mobile v-for="item in items" :item="item" :key="item.sku" :headers="headers"/>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </section>
</template>
<script>
import ProductOptionsListTableLine from '@/components/Catalog/ProductOptions/ProductOptionsListTableLine'
import ProductOptionsListTableLineMobile from '@/components/Catalog/ProductOptions/ProductOptionsListTableLineMobile'
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductOptionsListTable',
  components: {
    ProductOptionsListTableLine,
    ProductOptionsListTableLineMobile
  },
  props: {
    productOptions: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    pagination: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      search: '',
      headers: [
        { text: this.$t('Name'), align: 'left', width: '35%', value: 'name' },
        { text: this.$t('Types'), align: 'left', width: '35%', sortable: false, value: 'type' },
        { text: this.$t('Sort'), align: 'left', width: '30%', value: 'position' },
        { text: this.$t('Action'), align: 'left', width: 'auto', sortable: false, value: 'actions' }
      ],
      mobileButtons: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    debouncedSearch: _.debounce(function() {
      this.searched();
    }, 500),
    updateOptions (value) {
      value.search = this.search
      this.$emit('update:options', value)
    },
    searched () {
      this.$emit('searched', this.search)
    },
    openAddNewDialog () {
      this.$bus.$emit('catalog-product-option-new', true)
    },
    uploadFile (event) {
      if (event.target.files[0] !== undefined) {
        let file = event.target.files[0]
        this.$bus.$emit('notification-progress-start')
        this.$store.dispatch('catalog/product/importProducts', { file, token: this.token, type: 'options'})
          .then(res => {
            this.$store.dispatch('notification/spawnNotification', {
              type: res.status,
              message: this.$t(res.message),
              action1: { label: this.$t('OK') }
            })
          })
          .finally(() => {
            this.$bus.$emit('notification-progress-stop')
          })
      }
    },
    exportFile() {
      this.$bus.$emit('notification-progress-start')
      this.$store.dispatch('catalog/product/exportProductsCsv', { token: this.token, type: 'options'}).finally(() => {
        this.$bus.$emit('notification-progress-stop')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.product-options-list-table {
  &__root {
    padding: 16px;
  }
}

.mobile-search {
  padding: 0;
  margin-bottom: 16px;

  .mobile-icon {
    display: none;
  }
  @media (max-width: 680px) {
    .desktop-buttons {
      display: none;
    }
    .mobile-icon {
      position: relative;
      display: block;
      margin-left: 5px;
      .mobile-buttons {
        position: absolute;
        right: 0;
        top: 36px;
        z-index: 1;
        padding: 20px;
        background: #ffff;
        padding-bottom: 32px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: rgb(0 0 0 / 64%) 0px 1px 2px;
        background-image: none;
        overflow: hidden;
        border-radius: 5px;
        button {
          width: 94%;
          margin-top: 10px;
        }
      }
    }
  }
}

.orders-table {
  &__body {
    &_desktop {
      display: table-row-group;

      @media (max-width: 600px) {
        display: none !important;
      }
    }

    &_mobile {
      display: none;

      @media (max-width: 600px) {
        display: table-row-group;
      }
    }
  }
}
</style>
<style lang="scss">
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.orders-table {
  table {
    thead {
      tr {
        white-space: nowrap;
      }
    }

    @media (max-width: 599px) {
      position: relative;
      padding-top: 64px;

      .v-data-table-header-mobile {
        tr {
          th {
            position: absolute;
            top: 0;
            width: -webkit-fill-available;
            padding-top: 0 !important;
            height: 64px !important;
          }
        }
      }
    }
  }
}
</style>
